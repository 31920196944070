import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  TextareaAutosize,
} from '@material-ui/core';
import { Link, RouteComponentProps } from 'react-router-dom';
import { getAllCountry } from 'app/entities/person/person.reducer';
import { getCity } from 'app/entities/sub-contractor/subcontractor.reducer';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import CustomToolbarComponent from '../../../../../../entities/service-area/CustomToolbarComponent';
import { getDateFormat } from 'app/shared/util/calender-utils';
import MomentUtils from '@date-io/moment';
import { Autocomplete } from '@material-ui/lab';
import '../../../form.scss';
import { IRootState } from 'app/shared/reducers';
import { useLocation } from 'react-router';
import { ICrudGetAction } from 'react-jhipster';
import axios from 'axios';
import { ACTION_TYPES, VettingApplicantsState } from 'app/modules/vetting/applicants/applicants.reducer';
import { toast } from 'react-toastify';
import { handleIncrementDecrement } from 'app/modules/employeeApplicationForm/applicationForm.reducer';
import { toNumber } from 'lodash';
import {
  storeEmploymentHistoryVerification,
  getApplicantDetailsForEmploymentHistoryVerification,
  getReferenceFormDetail,
} from '../../../../../applicantsVerification/ApplicantVerification.reducer';

interface Props {
  storeAboutPositionSection: (data: any) => void;
  handleIncrementDecrement: (tag: string) => void;
  setting: any;
}
interface FormData {
  applicant_employment: boolean;
  previous_job_title: string;
  known_from: string;
  known_till: string;
  job_type: 'FULLTIME' | 'PARTTIME';
  weekly_working_hours: number;
  sick_leaves: number;
  annual_leaves: number;
  job_responsibilities: string;
  reasons_of_leaving: string;
  disciplinary_action: boolean;
  disciplinary_action_details: string;
  current_disciplinary_action: boolean;
  current_disciplinary_action_details: string;
  reemploy_applicant: boolean;
  reemploy_applicant_details: string;
  referred_person: boolean;
  full_name: string;
  job_title: string;
  relation_to_applicant: string;
  post_code: number;
  place: string;
  country: string;
  city: string;
  information_verified: boolean;
  reference_details_id: string;
}

export interface OtherRefrenceProps extends Props, StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const OtherRefrenceForm = (props: OtherRefrenceProps) => {
  const [countriesManual, setcountriesManual] = useState([]);

  const [filterTag, setFilterTag] = useState('FULLTIME');
  const [allCity, setAllCity] = useState([]);
  const [values, setValues] = useState({});
  const [otherReferenceInformation, setOtherReferenceInformation] = useState({});
  const location = useLocation();
  const [formData, setFormData] = useState<FormData>({
    applicant_employment: false,
    previous_job_title: '',
    known_from: '',
    known_till: '',
    job_type: 'FULLTIME',
    weekly_working_hours: 0,
    sick_leaves: 0,
    annual_leaves: 0,
    job_responsibilities: '',
    reasons_of_leaving: ' ',
    disciplinary_action: false,
    disciplinary_action_details: '',
    current_disciplinary_action: false,
    current_disciplinary_action_details: '',
    reemploy_applicant: false,
    reemploy_applicant_details: '',
    referred_person: false,
    full_name: '',
    job_title: '',
    relation_to_applicant: '',
    post_code: 0,
    place: '',
    country: '',
    city: '',
    information_verified: false,
    reference_details_id: props.applicant && props.applicant.id,
  });
  const urlId = location.pathname.split('/').pop();

  useEffect(() => {
    setFormData({ ...formData, reference_details_id: props.applicant?.id });
  }, [props.applicant]);

  useEffect(() => {
    props.getApplicantDetailsForEmploymentHistoryVerification(urlId);
    props.getReferenceFormDetail(urlId);
  }, [urlId]);

  useEffect(() => {
    if (props.refForm && props.refForm.employment_history === true) {
      props.history.push('/responseSubmitted');
    }
  }, [props.refForm]);

  const toggleButtons = btn => {
    if (btn === 'FULLTIME') {
      setFilterTag('FULLTIME');
      setFormData({ ...formData, job_type: 'FULLTIME' });
    } else if (btn === 'PARTTIME') {
      setFilterTag('PARTTIME');
      setFormData({ ...formData, job_type: 'PARTTIME' });
    }
  };
  const handleDateOfFrom = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setValues(oldValues => ({ ...oldValues, ['from']: d }));
    setFormData({ ...formData, known_from: d });
  };
  const handleDateOfTill = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setValues(oldValues => ({ ...oldValues, ['till']: d }));
    setFormData({ ...formData, known_till: d });
  };
  const setFormValues = e => {
    setFormData({ ...formData, [e.target.name]: !!e.target.value });
    // setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
  };
  const setAutoValues = (e, name) => {
    setValues(oldValues => ({ ...oldValues, [name]: e.target.innerText }));
    setFormData({ ...formData, [name]: e.target.innerText });
  };

  const isFormDataEmpty = formData => {
    const result =
      !(formData['applicant_employment'] === undefined) &&
      !(formData['personKnows'] === undefined) &&
      !(formData['information_verified'] === undefined) &&
      !(formData['referred_person'] === undefined) &&
      !(formData['reemploy_applicant'] === undefined) &&
      !(formData['current_disciplinary_action'] === undefined) &&
      !(formData['disciplinary_action'] === undefined) &&
      !(formData['applicant_employment'] === undefined);

    return result;
  };

  const handleSubmit = async () => {
    try {
      if (isFormDataEmpty(formData)) {
        toast.error('All fields must be filled!');
      } else {
        const response = await axios.post('/api/storeEmploymentReference', formData);
        toast.success('Reference form submitted successfully!');
      }
    } catch (error) {
      toast.error('Reference form did not submitted successfully!');
    }
  };

  return (
    <Container maxWidth={'lg'}>
      <Grid container xs={12} className="grid-change" spacing={3}>
        <Grid item xs={12} md={8}>
          <div style={{ marginRight: '15px', fontSize: '20px' }}>
            <b>We would appreciate your help and ask that you kindly complete the below questionnaire at your earliest convenience</b>
          </div>
          <br />
          <div style={{ fontSize: '16px' }}>
            <b>Please confirm applicant&apos;s employment at {props.applicant && props.applicant.organization}.</b>
          </div>
          <div style={{ marginTop: '1rem' }}>
            <RadioGroup
              name="applicant_employment"
              onChange={setFormValues}
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel style={{ marginLeft: '45px' }} value="" control={<Radio />} label="No" />
            </RadioGroup>
          </div>
          <br />
          <div className="main-container">
            <div className="stack-wrapper">
              <TextField
                fullWidth
                size="medium"
                className="otherTextField custom-muiFields"
                type="text"
                name="job_title_at_organization"
                variant="outlined"
                label="Job title at your organization"
                onChange={e => setFormData({ ...formData, previous_job_title: e.target.value })}
              />

              <div className=" stack-input-wrapper">
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    className="form-control aboutPositionStartWorkDateIcon custom-muiFields"
                    inputVariant="outlined"
                    name="expected_date_start"
                    label="From"
                    format={'DD-MM-YY'}
                    value={values['from'] ? values['from'] : null}
                    placeholder={'From'}
                    keyboardIcon={<img src="content/images/icons8-calendar-22.png" />}
                    InputAdornmentProps={{ position: 'end' }}
                    onChange={date => handleDateOfFrom(date)}
                  />{' '}
                </MuiPickersUtilsProvider>

                <span className="field-dash">-</span>

                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    className="form-control aboutPositionStartWorkDateIcon custom-muiFields"
                    inputVariant="outlined"
                    name="expected_date_start"
                    label="Till"
                    format={'DD-MM-YY'}
                    value={values['till'] ? values['till'] : null}
                    placeholder={'Till'}
                    keyboardIcon={<img src="content/images/icons8-calendar-22.png" />}
                    InputAdornmentProps={{ position: 'end' }}
                    onChange={date => handleDateOfTill(date)}
                    minDate={moment(formData.known_from)}
                    maxDate={moment()}
                  />{' '}
                </MuiPickersUtilsProvider>
              </div>
            </div>

            <div className="stack-wrapper">
              <div className="stack-input-wrapper">
                <Button
                  fullWidth
                  className={filterTag === 'FULLTIME' ? 'active-professional-history-buttons' : 'not-active-professional-history-buttons'}
                  onClick={() => {
                    toggleButtons('FULLTIME');
                  }}
                >
                  Full Time
                </Button>
                <Button
                  fullWidth
                  style={{ marginLeft: '18px' }}
                  className={filterTag === 'PARTTIME' ? 'active-professional-history-buttons' : 'not-active-professional-history-buttons'}
                  onClick={() => {
                    toggleButtons('PARTTIME');
                  }}
                >
                  Part Time
                </Button>
              </div>
              <TextField
                className="custom-muiFields"
                fullWidth
                required
                label="Weekly Working Hours"
                variant="outlined"
                data-cy="charge-rate"
                type="number"
                name="weekly_hours"
                onChange={e => setFormData({ ...formData, weekly_working_hours: toNumber(e.target.value) })}
                inputProps={{
                  step: '1',
                  min: 1,
                }}
              />
            </div>

            <div className="stack-wrapper">
              <TextField
                fullWidth
                required
                id="service-area-charge-rate"
                className="service-area-fields custom-muiFields"
                label="Sick Leave(s)"
                variant="outlined"
                data-cy="charge-rate"
                type="number"
                name="sick_leaves"
                onChange={e => setFormData({ ...formData, sick_leaves: toNumber(e.target.value) })}
                inputProps={{
                  step: '1',
                  min: 1,
                }}
              />
              <TextField
                fullWidth
                required
                id="service-area-charge-rate"
                className="service-area-fields custom-muiFields"
                label="Annual Leave(s)"
                variant="outlined"
                data-cy="charge-rate"
                type="number"
                name="annual_leave"
                onChange={e => setFormData({ ...formData, annual_leaves: toNumber(e.target.value) })}
                inputProps={{
                  step: '1',
                  min: 1,
                }}
              />
            </div>

            <TextField
              className="custom-muiFields"
              label="Jobs Responsibilities"
              name="job_responsibilities"
              onChange={e => setFormData({ ...formData, job_responsibilities: e.target.value })}
              multiline
              variant="outlined"
              minRows={4}
            />

            <TextField
              className="custom-muiFields"
              label="Reasons for Leaving"
              name="reason_for_leaving"
              onChange={e => setFormData({ ...formData, reasons_of_leaving: e.target.value })}
              multiline
              variant="outlined"
              minRows={4}
            />

            <div style={{ fontSize: '16px' }}>
              <b>Has the applicant received any documented disciplinary action during the period they were working for your company?</b>
            </div>
            <div>
              <RadioGroup
                aria-label="gender"
                name="disciplinary_action"
                onChange={setFormValues}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel style={{ marginLeft: '45px' }} value="" control={<Radio />} label="No" />
              </RadioGroup>
            </div>
            <TextField
              className="custom-muiFields"
              label="Write Details"
              name="disciplinary_action_detail"
              onChange={e => setFormData({ ...formData, disciplinary_action_details: e.target.value })}
              multiline
              variant="outlined"
              minRows={4}
            />

            <div style={{ fontSize: '16px' }}>
              <b>Is there any ongoing investigation or disciplinary action against the applicant?</b>
            </div>
            <div>
              <RadioGroup
                aria-label="gender"
                name="current_disciplinary_action"
                onChange={setFormValues}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel style={{ marginLeft: '45px' }} value="" control={<Radio />} label="No" />
              </RadioGroup>
            </div>
            <TextField
              className="custom-muiFields"
              label="Write Details"
              name="current_investigation_detail"
              onChange={e => setFormData({ ...formData, current_disciplinary_action_details: e.target.value })}
              multiline
              variant="outlined"
              minRows={4}
            />

            <div style={{ fontSize: '16px' }}>
              <b>Would you re-employ the applicant? If &apos;No&apos; please give a reason.</b>
            </div>
            <div>
              <RadioGroup
                aria-label="gender"
                name="reemploy_applicant"
                onChange={setFormValues}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel style={{ marginLeft: '45px' }} value="" control={<Radio />} label="No" />
              </RadioGroup>
            </div>

            <TextField
              className="custom-muiFields"
              label="Write Details"
              name="reemploy_detail"
              onChange={e => setFormData({ ...formData, reemploy_applicant_details: e.target.value })}
              multiline
              variant="outlined"
              minRows={4}
            />
          </div>
          <br />
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="otherApplicantData">
            <div className="sideBar-wrapper">
              <div className="sideBar-data-wrapper">
                <h5 className="applicantText">Applicant Info</h5>
                <p className="applicantText2">{props.applicant && props.applicant.firstName + ' ' + props.applicant.lastName}</p>
              </div>
              <div className="sideBar-data-wrapper">
                <h5 className="applicantText">Post Applied</h5>
                <p className="applicantText2">{props.applicant && props.applicant.appliedFor}</p>
              </div>
              <div className="sideBar-data-wrapper">
                <h5 className="applicantText">Screener Info</h5>
                <p className="applicantText2">{props.applicant && props.applicant.organization}</p>
                <p className="applicantText2">
                  {otherReferenceInformation &&
                    otherReferenceInformation['screener_info'] &&
                    otherReferenceInformation['screener_info']['email']}
                </p>
              </div>
              <div className="sideBar-data-wrapper">
                <h5 className="applicantText">Referee</h5>
                <p className="applicantText2">{otherReferenceInformation && otherReferenceInformation['referee_name']}</p>
                <p className="applicantText2">{otherReferenceInformation && otherReferenceInformation['referee_email']}</p>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className="otherReferenceDiv">
        <span style={{ marginLeft: '20px', color: '#924EE4', opacity: '1' }}>Declaration</span>
      </div>
      <br />
      <div className="otherReferenceDiv2">
        <div style={{ fontSize: '16px' }}>
          <b>Are you the person named above as referee?</b>
        </div>
        <br />
        <div>
          <RadioGroup
            aria-label="gender"
            name="referred_person"
            onChange={setFormValues}
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel style={{ marginLeft: '45px' }} value="" control={<Radio />} label="No" />
          </RadioGroup>
        </div>
        <br />
        <Grid container xs={12} spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              style={{ width: '100%' }}
              type="text"
              name="full_name"
              onChange={e => setFormData({ ...formData, full_name: e.target.value })}
              variant="outlined"
              label="Your Full Name"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              style={{ width: '100%' }}
              type="text"
              name="job_title"
              onChange={e => setFormData({ ...formData, job_title: e.target.value })}
              variant="outlined"
              label="Job Title"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <Autocomplete
                options={['Friend', 'Fellow']}
                noOptionsText={'No Records'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                className="PersonAutocomplete custom-muiFields"
                onChange={event => setAutoValues(event, 'relation_to_applicant')}
                value={values['relation_to_applicant'] ? values['relation_to_applicant'] : null}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    className="personAutocompleteTextfield autocompleteLabel"
                    label="Relation to the applicant"
                    margin="normal"
                    required
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <Grid container xs={12} spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              style={{ width: '100%' }}
              type="text"
              name="applied_position"
              variant="outlined"
              label="Post Code"
              onChange={e => setFormData({ ...formData, post_code: toNumber(e.target.value) })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              style={{ width: '100%' }}
              type="text"
              name="applied_position"
              variant="outlined"
              label="City"
              onChange={e => setFormData({ ...formData, city: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              style={{ width: '100%' }}
              type="text"
              name="applied_position"
              variant="outlined"
              label="Country"
              onChange={e => setFormData({ ...formData, country: e.target.value })}
            />
          </Grid>
        </Grid>
        <br />
        <div style={{ fontSize: '16px' }}>
          <b>The above information has been provided without prejudice and correct to the best of my knowledge and belief</b>
        </div>
        <br />
        <div>
          <RadioGroup
            aria-label="gender"
            name="information_verified"
            onChange={setFormValues}
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel style={{ marginLeft: '45px' }} value="" control={<Radio />} label="No" />
          </RadioGroup>
        </div>
      </div>
      <br />
      <div className="other_reference_action_btn">
        <Button id="cancel_button" className="custom_save">
          Cancel
        </Button>
        <div>
          <Button id="reference_save_button" className="custom_save" onClick={handleSubmit}>
            Save & Submit
          </Button>
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  cities: storeState.subcontractor.CityEntity,
  allcountries: storeState.person.AllCountryEntity,
  cityLoading: storeState.subcontractor.loading,
  applicant: storeState.VettingReferenceVerification.applicantData,
  refForm: storeState.VettingReferenceVerification.refFormDetails,
});
const mapDispatchToProps = {
  getCity,
  getAllCountry,
  storeEmploymentHistoryVerification,
  getApplicantDetailsForEmploymentHistoryVerification,
  getReferenceFormDetail,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OtherRefrenceForm);
