import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { ToastContainer, toast } from 'react-toastify';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';
import { defaultValue as IAboutPosition } from 'app/shared/model/application-form-about-position.model';
import { defaultValue as IAppFormPersonalInfo } from 'app/shared/model/application-form-personal-info.model';
import { defaultValue as IEmployment } from 'app/shared/model/application-form-employment.model';

export const ACTION_TYPES = {
  STORE_APPLICATION_FORM: 'hr/STORE_APPLICATION_FORM',
  GET_APPLICATION_FORM_DATA: 'hr/GET_APPLICATION_FORM_DATA',
  STORE_ABOUT_POSITION: 'hr/STORE_ABOUT_POSITION',
  UPDATE_ABOUT_POSITION: 'hr/UPDATE_ABOUT_POSITION',
  STORE_PERSONAL_DETAILS: 'hr/STORE_PERSONAL_DETAILS',
  UPDATE_PERSONAL_DETAILS: 'hr/UPDATE_PERSONAL_DETAILS',
  STORE_MEDICAL_DETAILS: 'hr/STORE_MEDICAL_DETAILS',
  STORE_EMPLOYMENT_HISTORY: 'hr/STORE_EMPLOYMENT_HISTORY',
  STORE_DATA_PROTECTION: 'hr/STORE_DATA_PROTECTION',
  STORE_DOCUMENTS_REQUIRED: 'hr/STORE_DOCUMENTS_REQUIRED',
  GET_EMPLOYEE_FORM_INFORMATION: 'hr/GET_EMPLOYEE_FORM_INFORMATION',
  STEP_INCREMENT: 'INCREMENT',
  STEP_DECREMENT: 'DECREMENT',
  FORM_TOGGLE: 'FORM_TOGGLE',
  ABOUT_POSITION_FORM_RESET: 'hr/ABOUT_POSITION_FORM_RESET',
  UPDATE_APPLICANT: 'hr/UPDATE_APPLICANT',
  GET_FORM_DETAILS: 'hr/GET_FORM_DETAILS',
  SET_LOADING: 'hr/SET_LOADING',
  SET_LOADING_SUCCESS: 'hr/SET_LOADING_SUCCESS'
};

const initialState = {
  formData: [] as any,
  employeeFormData: {} as any,
  formSteps: [
    { id: 0, completed: true, value: 'TERMS' },
    { id: 1, completed: false, value: 'ABOUT THE POSITION' },
    { id: 2, completed: false, value: 'ABOUT YOU' },
    { id: 3, completed: false, value: 'PROFESSIONAL HISTORY' },
    { id: 4, completed: false, value: 'DATA PROTECTION' },
    { id: 5, completed: false, value: 'DOCUMENTS REQUIRED' },
  ],
  activeModule: 0,
  aboutPosition: IAboutPosition,
  personalInfo: IAppFormPersonalInfo,
  employmentHistory: IEmployment,
  historyLoading: false,
  loading: false,
  errorMessage: null,
  updating: false,
  updateSuccess: false,
  editEmployeeForm: false,
  formDetails: null,
};

const vettingApiUrl = 'api/vetting';

export type ApplicationFormState = Readonly<typeof initialState>;
export default (state: ApplicationFormState = initialState, action): ApplicationFormState => {
  switch (action.type) {
    case ACTION_TYPES.STORE_EMPLOYMENT_HISTORY:
      return {
        ...state,
        historyLoading: false,
        updateSuccess: true,
        employmentHistory: action.payload,
      };
    case ACTION_TYPES.STORE_ABOUT_POSITION:
      return {
        ...state,
        aboutPosition: action.payload,
        loading: false,
      };
    case ACTION_TYPES.UPDATE_ABOUT_POSITION:
      return {
        ...state,
        aboutPosition: action.payload,
        loading: false,
      };
    case ACTION_TYPES.STORE_PERSONAL_DETAILS:
      return {
        ...state,
        personalInfo: action.payload,
        loading: false,
      };
    case ACTION_TYPES.UPDATE_PERSONAL_DETAILS:
      return {
        ...state,
        personalInfo: action.payload,
        loading: false,
      };
    case ACTION_TYPES.UPDATE_APPLICANT:
      return {
        ...state,
        loading: false,
      };
    case ACTION_TYPES.GET_APPLICATION_FORM_DATA:
      return {
        ...state,
        formData: action.payload.data,
        loading: false,
      };
    case REQUEST(ACTION_TYPES.GET_EMPLOYEE_FORM_INFORMATION):
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case SUCCESS(ACTION_TYPES.GET_EMPLOYEE_FORM_INFORMATION):
      return {
        ...state,
        loading: false,
        employeeFormData: action.payload || {},
        editEmployeeForm: true,
      };
    case FAILURE(ACTION_TYPES.GET_EMPLOYEE_FORM_INFORMATION):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case ACTION_TYPES.STEP_INCREMENT:
      return {
        ...state,
        formSteps: action.payload.array,
        activeModule: action.payload.step,
      };
    case ACTION_TYPES.STEP_DECREMENT:
      return {
        ...state,
        activeModule: action.payload.step,
      };
    case ACTION_TYPES.FORM_TOGGLE:
      return {
        ...state,
        activeModule: action.payload,
      };
    case ACTION_TYPES.ABOUT_POSITION_FORM_RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.GET_FORM_DETAILS:
      return {
        ...state,
        formDetails: action.payload,
        loading: false,
      };
    case ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ACTION_TYPES.SET_LOADING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const handleIncrementDecrement = (type, active) => dispatch => {
  if (type === ACTION_TYPES.STEP_INCREMENT) {
    const newItemsArray = initialState.formSteps;
    const activeModule = initialState.activeModule;
    const item = { ...newItemsArray[active], completed: true };
    newItemsArray[active] = item;
    const data = { array: newItemsArray, step: active };
    dispatch({
      type: ACTION_TYPES.STEP_INCREMENT,
      payload: data,
    });
  } else {
    dispatch({
      type: ACTION_TYPES.STEP_DECREMENT,
      payload: active,
    });
  }
};

export const formToggle = data => ({
  type: ACTION_TYPES.FORM_TOGGLE,
  payload: data,
});

export const storeApplicationForm = (data, files) => {
  let result;
  axios
    .post('api/applicationForm', data)
    .then(res => {
      result = res;
      files.append('person_id', res.data.person_id);
      files.append('person_name', res.data.person_name);
      axios
        .post('api/storeApplicationFiles', files, { headers: { 'content-Type': 'multipart/form-data' } })
        .then(response => {
          toast.success('Form submitted');
        })
        .catch(err => {
          toast.error('Error :' + err);
        });
    })
    .catch(err => {
      toast.error('Error : ' + err);
    });

  return result;
};

export const storeAboutPositionSection = data => dispatch => {
  dispatch({ type: ACTION_TYPES.SET_LOADING });
  let result;
  axios
    .post(`api/aboutPosition`, data)
    .then(res => {
      dispatch(handleIncrementDecrement('INCREMENT', 2));
      dispatch({
        type: ACTION_TYPES.STORE_ABOUT_POSITION,
        payload: res.data,
      });
    })
    .catch(err => {
      toast.error('Error : ' + err);
      dispatch({ type: ACTION_TYPES.SET_LOADING_SUCCESS });
    });
  return result;
};

export const updateAboutPositionSection = data => dispatch => {
  dispatch({ type: ACTION_TYPES.SET_LOADING });
  let result;
  axios
    .put(`api/aboutPosition/${data.id}`, data)
    .then(res => {
      dispatch(handleIncrementDecrement('INCREMENT', 2));
      dispatch({
        type: ACTION_TYPES.UPDATE_ABOUT_POSITION,
        payload: res.data,
      });
    })
    .catch(err => {
      toast.error('Error : ' + err);
      dispatch({ type: ACTION_TYPES.SET_LOADING_SUCCESS });
    });
  return result;
};

export const storePersonalDetailsSection = (data, files) => dispatch => {
  dispatch({ type: ACTION_TYPES.SET_LOADING });
  let result;
  axios
    .post(`api/personalInformation`, data)
    .then(res => {
      files.append('id', res.data.id);
      axios
        .post(`api/personalInformationFiles`, files, { headers: { 'content-Type': 'multipart/form-data' } })
        .then(response => {
          dispatch(handleIncrementDecrement('INCREMENT', 3));
          dispatch({
            type: ACTION_TYPES.STORE_PERSONAL_DETAILS,
            payload: res.data,
          });
        })
        .catch(err => {
          toast.error('Error : ' + err);
        });
    })
    .catch(err => {
      toast.error('Error : ' + err);
      dispatch({ type: ACTION_TYPES.SET_LOADING_SUCCESS });
    });
  return result;
};

export const updatePersonalDetailsSection = (data, files) => dispatch => {
  dispatch({ type: ACTION_TYPES.SET_LOADING });
  let result;
  axios
    .put(`api/personalInformation/${data.id}`, data)
    .then(res => {
      files.append('id', res.data.id);
      axios
        .post(`api/personalInformationFiles`, files, { headers: { 'content-Type': 'multipart/form-data' } })
        .then(response => {
          dispatch(handleIncrementDecrement('INCREMENT', 3));
          dispatch({
            type: ACTION_TYPES.UPDATE_PERSONAL_DETAILS,
            payload: res.data,
          });
        })
        .catch(err => {
          toast.error('Error : ' + err);
        });
    })
    .catch(err => {
      toast.error('Error : ' + err);
      dispatch({ type: ACTION_TYPES.SET_LOADING_SUCCESS });
    });
  return result;
};

export const storeEmploymentHistory = (data, files) => dispatch => {
  dispatch({ type: ACTION_TYPES.SET_LOADING });
  let result;
  axios
    .post(`api/employmentHistory`, data)
    .then(res => {
      files.append('ref_counter', res.data.ref_counter);
      files.append('edu_counter', res.data.edu_counter);
      files.append('create', true);
      axios
        .post(`api/professionalHistoryFiles`, files, { headers: { 'content-Type': 'multipart/form-data' } })
        .then(response => {
          dispatch({ type: ACTION_TYPES.SET_LOADING_SUCCESS });
          dispatch(handleIncrementDecrement('INCREMENT', 4));
        })
        .catch(err => {
          toast.error('Error : ' + err);
        });
    })
    .catch(err => {
      toast.error('Error : ' + err);
      dispatch({ type: ACTION_TYPES.SET_LOADING_SUCCESS });
    });

  return result;
};

export const updateEmploymentHistory = (data, files) => dispatch => {
  dispatch({ type: ACTION_TYPES.SET_LOADING });
  let result;
  axios
    .put(`api/employmentHistory/${data.person_id}`, data)
    .then(res => {
      files.append('ref_counter', res.data.ref_counter);
      files.append('edu_counter', res.data.edu_counter);
      files.append('create', false);
      axios
        .post(`api/professionalHistoryFiles`, files, { headers: { 'content-Type': 'multipart/form-data' } })
        .then(response => {
          dispatch({ type: ACTION_TYPES.SET_LOADING_SUCCESS });
          dispatch(handleIncrementDecrement('INCREMENT', 4));
        })
        .catch(err => {
          toast.error('Error : ' + err);
        });
    })
    .catch(err => {
      toast.error('Error : ' + err);
      dispatch({ type: ACTION_TYPES.SET_LOADING_SUCCESS });
    });

  return result;
};

export const storeVettingConsent = data => dispatch => {
  dispatch({ type: ACTION_TYPES.SET_LOADING });
  let result;
  axios
    .post(`api/vettingConsent`, data)
    .then(res => {
      dispatch({ type: ACTION_TYPES.SET_LOADING_SUCCESS });
      dispatch(handleIncrementDecrement('INCREMENT', 5));
    })
    .catch(err => {
      toast.error('Error : ' + err);
      dispatch({ type: ACTION_TYPES.SET_LOADING_SUCCESS });
    });

  return result;
};

export const storeRequiredDocuments = data => async dispatch => {
  dispatch({ type: ACTION_TYPES.SET_LOADING });
  await axios
    .post(`api/vettingDocuments`, data)
    .then(res => {
      toast.success('Form submitted');
      return res.data;
    })
    .catch(err => {
      toast.error('Error : ' + err);
      dispatch({ type: ACTION_TYPES.SET_LOADING_SUCCESS });
      return { success: false, error: err.message };
    });
    return { success: true};
};

export const getEmployeeFormInformation = employeeId => dispatch => {
  dispatch({ type: REQUEST(ACTION_TYPES.GET_EMPLOYEE_FORM_INFORMATION)});
  let result;
  dispatch(handleIncrementDecrement('INCREMENT', 1));
  axios
    .get(`api/vetting/getapplicantinformation/${employeeId}`)
    .then(res => {
    dispatch({
        type: SUCCESS(ACTION_TYPES.GET_EMPLOYEE_FORM_INFORMATION),
      payload: res.data,
    });
      result = res.data;
    })
    .catch(err => {
      toast.error('Error : ' + err);
      dispatch({ type: FAILURE(ACTION_TYPES.SET_LOADING_SUCCESS)});
    });
  return result;
};

export const aboutPositionFormReset = () => ({
  type: ACTION_TYPES.ABOUT_POSITION_FORM_RESET,
});

export const updateEntity = (data: { id: number; newState: string; oldState: string }) => async () => {
  return {
    type: ACTION_TYPES.UPDATE_APPLICANT,
    payload: await axios.post(`${vettingApiUrl}/updateapplicant`, data),
  };
};

export const getFormDetail = id => dispatch => {
  let result;
  axios
    .get(`api/vettingFormSubmitted/${id}`)
    .then(res => {
      dispatch({
        type: ACTION_TYPES.GET_FORM_DETAILS,
        payload: res.data,
      });
    })
    .catch(err => {
      toast.error('Error : ' + err);
    });
  return result;
};

export const storeVettingSignOff = data => dispatch => {
  let result;
  axios
    .post(`api/vettingSignOff`, data)
    .then(res => {
      toast.success('Application Successfully Signed Off');
    })
    .catch(err => {
      toast.error('Error : ' + err);
    });

  return result;
};

export const getEmployeeFormInformationForUser = employeeId => dispatch => {
  let result;
  axios
    .get(`api/vetting/getapplicantinformation/${employeeId}`)
    .then(res => {
      dispatch({
        type: ACTION_TYPES.STORE_PERSONAL_DETAILS,
        payload: res.data,
      });
      dispatch(handleIncrementDecrement('INCREMENT', res.data.vetting_stage || 0));
      result = res.data;
    })
    .catch(err => {
      toast.error('Error : ' + err);
    });
  return result;
};