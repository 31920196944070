import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { getReport } from './timesheet-summary.reducer';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { getEntities as getPersons, reset as resetPerson, getPersonForFilters } from 'app/entities/person/person.reducer';
import { getEntities as getLocations, reset as resetLoc } from 'app/entities/service-area/service-area.reducer';
import { getEntities as getPositions, reset as resetPosition } from 'app/entities/designation/designation.reducer';
import { getAllEntities as getSubcontractors, reset as resetSub } from '../sub-contractor/subcontractor.reducer';
import timePeriod from '../../shared/data/time_period_other.json';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { getPersonalSettings } from '../../modules/Setting/Personal-Setting.reducer';
import { getOrganizationSettings } from '../../modules/Setting/generalSettings.reducer';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CustomToolbarComponent from '../service-area/CustomToolbarComponent';
import '../../../utils/GlobalCssRequiredInWebsite.scss';
import { Storage } from 'react-jhipster';
import { Table, SubRowAsync } from './timesheet-react-table';
import Spinner from 'app/commonUI/spinner/spinner';
import { Autocomplete } from '@material-ui/lab';
import { Grid, IconButton, TextField } from '@material-ui/core';
import {
  handleFilterCurrentWeekStartDate,
  handleFilterCurrentWeekEndDate,
  handleFilterLastWeekStartDate,
  handleFilterLastWeekEndDate,
  getSelectedTimePeriodFilter,
} from '../../../utils/TimeFormatAndTimezone';
import SearchBar from 'material-ui-search-bar';
import { convertToPDF } from './pdfData';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import GetAppIcon from '@material-ui/icons/GetApp';
import Popper from '@material-ui/core/Popper';
import '../entities-reports.scss';
import { dateFTConverter } from '../../../utils/TimeFormatAndTimezone';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl2: {
      margin: theme.spacing(1),
      fontWeight: 400,
      textDecoration: 'none !important',
      color: '#000000',
    },

    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    root: {
      '& > *': {
        borderBottom: 'unset!important',
        backgroundColor: ' #f9eef8!important',
      },
    },
    alertStyling: {
      background: 'lightyellow',
      width: '18vh',
      padding: '2vh',
    },
  })
);

export interface ITimesheetSummaryProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const TimesheetSummary = (props: ITimesheetSummaryProps) => {
  const [values, setValues] = useState({
    people: '',
    location: '',
    position: '',
    department: '',
    subcontractor: '',
    from_date: moment(handleFilterCurrentWeekStartDate()).format('YYYY-MM-DD'),
    to_date: moment(handleFilterCurrentWeekEndDate()).format('YYYY-MM-DD'),
    newPage: 1,
  });
  const classes = useStyles();
  const startDayOfWeek = Storage.local.get('IndividualSetting').start_day_of_week;
  const [selectedTimePeriod, setTimePeriod] = useState({ id: 3, text: 'Current week' });
  const [selectedDateTo, setSelectedDateTo] = React.useState<any | null>(
    getSelectedTimePeriodFilter(selectedTimePeriod.text, 'setSelectedDateTo')
  );
  const [selectedDateFrom, setSelectedDateFrom] = React.useState<any | null>(
    getSelectedTimePeriodFilter(selectedTimePeriod.text, 'setSelectedDateFrom')
  );
  const [selectedPerson, setSelectedPerson] = React.useState<any | null>(null);
  const [selectedLocation, setSelectedLocation] = React.useState<any | null>(null);
  const [selectedSubcontractor, setSelectedSubcontractor] = React.useState<any | null>(null);
  const [selectedPosition, setSelectedPosition] = React.useState<any | null>(null);
  const [selectedAction, setSelectedAction] = React.useState<any | null>(null);
  const TimeFormat = Storage.local.get('PersonalSetting').time_format;
  const Timezone = Storage.local.get('PersonalSetting').timezone;
  const DateFormat = Storage.local.get('PersonalSetting').date_format;
  const [timeFormatSettings, setTimeFormatSettings] = useState(TimeFormat);
  const [dateFormat, setdateFormat] = useState(DateFormat);
  const [timezoneFormat, setTimezoneFormat] = useState(Timezone);
  const [currency, setCurrency] = useState(props.settings.currency);
  const { timesheetSummaryList, match, loading } = props;
  const [searched, setSearched] = useState<string>('');
  const [newRows, setRows] = useState(props.timesheetSummaryList);
  const csvLinks = [''];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    props.resetSub();
    props.resetLoc();
    props.resetPerson();
    props.getLocations(-1, '');
    props.getSubcontractors(-1);
    props.getPersonForFilters();
  }, []);

  useEffect(() => {
    props.getReport(values);
  }, [values]);

  useEffect(() => {
    setCurrency(props.settings.currency);
  }, [props.settings]);

  useEffect(() => {
    setRows(props.timesheetSummaryList);
  }, [props.timesheetSummaryList]);

  useEffect(() => {
    setdateFormat(props.setting.date_format);
    setTimeFormatSettings(props.setting.time_format);
    setTimezoneFormat(props.setting.timezone);
  }, [props.setting]);

  const dateConverter = dateformat => {
    if (dateformat === null) {
      if (dateFormat === 1) {
        return 'DD-MM-YY';
      } else if (dateFormat === 2) {
        return 'DD-MM-YYYY';
      } else if (dateFormat === 3) {
        return 'MM/DD/YY';
      } else if (dateFormat === 4) {
        return 'MM/DD/YYYY';
      } else if (dateFormat === 5) {
        return 'DD.MM.YY';
      } else if (dateFormat === 6) {
        return 'DD.MM.YYYY';
      } else if (dateFormat === 7) {
        return 'YY/MM/DD';
      } else if (dateFormat === 8) {
        return 'YYYY/MM/DD';
      }
    } else {
      if (dateFormat === 1) {
        return moment(dateformat).format('DD-MM-YY');
      } else if (dateFormat === 2) {
        return moment(dateformat).format('DD-MM-YYYY');
      } else if (dateFormat === 3) {
        return moment(dateformat).format('MM/DD/YY');
      } else if (dateFormat === 4) {
        return moment(dateformat).format('MM/DD/YYYY');
      } else if (dateFormat === 5) {
        return moment(dateformat).format('DD.MM.YY');
      } else if (dateFormat === 6) {
        return moment(dateformat).format('DD.MM.YYYY');
      } else if (dateFormat === 7) {
        return moment(dateformat).format('YY/MM/DD');
      } else if (dateFormat === 8) {
        return moment(dateformat).format('YYYY/MM/DD');
      }
    }
  };

  const handleToggle = event => {
    setOpen(prevOpen => !prevOpen);
    setAnchorEl(event.currentTarget);
  };

  const header = [
    { label: 'Name', key: 'person' },
    { label: 'Start Date', key: 'startDate' },
    { label: 'End Date', key: 'endDate' },
    { label: 'Service Area', key: 'location' },
    { label: 'Position', key: 'description' },
    { label: 'Sign-In', key: 'timeFrom' },
    { label: 'Sign-Out', key: 'timeTo' },
    { label: 'Working Hours', key: 'actualTime' },
    { label: 'Rate', key: 'rate' },
    { label: 'Total Wage', key: 'actualPay' },
  ];

  const headersDetailed = [
    { label: 'Assignee Name', key: 'person' },
    { label: 'Position', key: 'description' },
    { label: 'Service Area', key: 'location' },
    { label: 'Start Date', key: 'startDate' },
    { label: 'End Date', key: 'endDate' },
    { label: 'Time From', key: 'timeFrom' },
    { label: 'Time To', key: 'timeTo' },
    { label: 'Sign In', key: 'checkIn' },
    { label: 'Sign Out', key: 'checkOut' },
    { label: 'Estimated Hours', key: 'scheduledTime' },
    { label: 'Working Hours', key: 'actualTime' },
    { label: 'Rate', key: 'rate' },
    { label: 'Estimated Wage', key: 'scheduledPay' },
    { label: 'Actual Wage', key: 'actualPay' },
    { label: 'Expenses', key: 'expenses' },
  ];

  const jsonData = [];
  const jsonDataDetailed = [];
  if (props.timesheetSummaryList.length > 0) {
    props.timesheetSummaryList[0]['payload'].forEach(element => {
      const data = { ...element['report']['0'] };
      data.startDate = dateConverter(data.startDate);
      data.endDate = dateConverter(data.endDate);
      jsonData.push(data);
    });
    props.timesheetSummaryList[0]['payload'].forEach(element => {
      element['report']['0']['PAYLOAD'].forEach(individualArray => {
        const data = { ...individualArray };
        data.timeFrom = moment(data.timeFrom).format(`${timeFormatSettings === 1 ? 'HH:mm' : 'hh:mm A'}`);
        data.timeTo = moment(data.timeTo).format(`${timeFormatSettings === 1 ? 'HH:mm' : 'hh:mm A'}`);
        data.checkIn = data.checkIn === null ? null : moment(data.checkIn).format(`${timeFormatSettings === 1 ? 'HH:mm' : 'hh:mm A'}`);
        data.checkOut = data.checkOut === null ? null : moment(data.checkOut).format(`${timeFormatSettings === 1 ? 'HH:mm' : 'hh:mm A'}`);
        data.startDate = dateConverter(data.startDate);
        data.endDate = dateConverter(data.endDate);
        jsonDataDetailed.push(data);
      });
    });
  }

  const csvReport = {
    data: jsonData,
    headers: header,
    filename: 'Time Card Report.csv',
  };

  const csvReportDetailed = {
    data: jsonDataDetailed,
    headers: headersDetailed,
    filename: 'TimeSheet_Summary_Report_Detailed.csv',
  };

  const handlePersonFilterValues = (_, e) => {
    if (e) {
      setSelectedPerson({ id: e.id, text: e.text });
      return setFilterValues(e.name, e.id);
    } else {
      setValues(oldValues => ({ ...oldValues, people: '' }));
    }
  };

  const handleSubcontractorFilterValues = (_, e) => {
    if (e) {
      setSelectedSubcontractor({ id: e.id, text: e.text });
      return setFilterValues(e.name, e.id);
    } else {
      setValues(oldValues => ({ ...oldValues, subcontractor: '' }));
    }
  };

  const handlePositionFilterValues = (_, e) => {
    if (e) {
      setSelectedPosition({ id: e.id, text: e.text });
      return setFilterValues(e.name, e.id);
    } else {
      setValues(oldValues => ({ ...oldValues, position: '' }));
    }
  };

  const handleLocationFilterValues = (_, e) => {
    if (e) {
      setSelectedLocation({ id: e.id, text: e.text });
      return setFilterValues(e.name, e.id);
    } else {
      setValues(oldValues => ({ ...oldValues, location: '' }));
    }
  };

  const setFilterValues = (name, value) => {
    setValues(oldValues => ({ ...oldValues, [name]: value }));
  };

  const handleDateFrom = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setSelectedDateFrom(e);
    if (d !== 'Invalid date') {
      setValues(oldValues => ({ ...oldValues, ['from_date']: d }));
    }
  };

  const handleDateTo = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setSelectedDateTo(e);
    if (d !== 'Invalid date') {
      setValues(oldValues => ({ ...oldValues, ['to_date']: d }));
    }
  };

  const handleTimePeriodFilter = (_, e) => {
    if (e) {
      setTimePeriod({ id: e.id, text: e.text });

      switch (e.id) {
        case 1:
          setValues(oldValues => ({ ...oldValues, ['from_date']: '', ['to_date']: moment().format('YYYY-MM-DD'), ['current_day']: '' }));
          setSelectedDateTo(null);
          setSelectedDateFrom(null);
          break;
        case 2:
          {
            const first = moment().format('YYYY-MM-DD');
            setValues(oldValues => ({ ...oldValues, ['from_date']: first, ['to_date']: first, ['current_day']: first }));
            setSelectedDateTo(first);
            setSelectedDateFrom(first);
          }
          break;
        case 3:
          {
            const first = handleFilterCurrentWeekStartDate();
            const last = handleFilterCurrentWeekEndDate();
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        case 4:
          {
            const first = handleFilterLastWeekStartDate();
            const last = handleFilterLastWeekEndDate();
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        case 5:
          {
            const first = moment().startOf('month').format('YYYY-MM-DD');
            const last = moment().endOf('month').format('YYYY-MM-DD');
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        case 6:
          {
            const first = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
            const last = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        default:
          setValues(oldValues => ({ ...oldValues, ['from_date']: '', ['to_date']: '', ['current_day']: '' }));
      }
    } else {
      setValues(oldValues => ({ ...oldValues, ['from_date']: '', ['to_date']: '', ['current_day']: '' }));
    }
  };

  const setTimePeriodValues = (first, second) => {
    setValues(oldValues => ({
      ...oldValues,
      ['from_date']: moment(first).format('YYYY-MM-DD'),
      ['to_date']: moment(second).format('YYYY-MM-DD'),
      ['current_day']: '',
    }));
  };
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: d => (
          <>
            <span>{d.person}</span>
          </>
        ),
        SubCell: () => null,
      },
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => (
          <>
            <span {...row.getToggleRowExpandedProps()} className="ArrowRightLeftIcon">
              {row.isExpanded ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
            </span>
          </>
        ),
        SubCell: () => null,
      },
      {
        Header: 'Date/Time',
        accessor(d) {
          return {
            original: d,
            formattedDate: (
              <span>
                <span className="timesheetStartDateCell">{dateConverter(d.startDate)}</span>
                {'  -  '}
                <span className="timesheetEndDateCell">{dateConverter(d.endDate)}</span>
              </span>
            ),
          };
        },
        Cell({ value }) {
          return value.formattedDate;
        },
        SubCell(cellProps) {
          const { original } = cellProps.value;
          return (
            <>
              {original.startDate}
              <br />
              <span className="timeclockShiftTime">
                {original.timeFrom ? moment(original.timeFrom).format(`${timeFormatSettings === 1 ? 'HH:mm' : 'hh:mm A'}`) : ''}
                {' - '}
                {original.timeTo ? moment(original.timeTo).format(`${timeFormatSettings === 1 ? 'HH:mm' : 'hh:mm A'}`) : ''}
              </span>
            </>
          );
        },
      },
      {
        Header: 'Service Area',
        accessor: d => d.location,
      },
      {
        Header: 'Position',
        accessor: d => <div style={{ textOverflow: 'ellipsis' }}>{d.description}</div>,
      },
      {
        Header: ' Sign-In  -  Sign-0ut ',
        accessor: d => (
          <span className="timesheetInOutCell">
            {d.checkIn ? moment(d.checkIn).format(`${timeFormatSettings === 1 ? 'HH:mm' : 'hh:mm A'}`) : ''}
            {' - '}
            <br />
            {d.checkOut ? moment(d.checkOut).format(`${timeFormatSettings === 1 ? 'HH:mm' : 'hh:mm A'}`) : ''}
          </span>
        ),
      },
      {
        Header: 'Working Hours',
        accessor: d => d.actualTime,
      },
      {
        Header: 'Rate',
        accessor: d => d.payRate,
      },
      {
        Header: 'Total Wage',
        accessor: d => d.actualPay,
      },
    ],
    []
  );
  let Tabledata = [];
  const newData = [];
  let rowData: any = [];
  const isDescending = true;
  if (newRows && newRows.length > 0) {
    if (newRows[0]['payload']) {
      rowData = newRows[0]['payload'];
    } else if (newRows[0]['report']) {
      rowData = newRows;
    }
    rowData?.forEach(element => {
      const data = { ...element['report']['0'] };
      newData.push(data);
      Tabledata = newData.sort((a, b) => {
        return isDescending
          ? new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
          : new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
      });
    });
  }
  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, loading, currency }) => <SubRowAsync row={row} rowProps={rowProps} loading={loading} currency={currency} />,
    []
  );
  const requestSearch = (searchedVal: string) => {
    setSearched(searchedVal);
    const value = searchedVal.toLowerCase();
    const filteredRows = props.timesheetSummaryList[0]['payload'].filter(data => {
      const row = { ...data['report']['0'] };
      return (
        (row.startDate && dateConverter(row.startDate).includes(value)) ||
        (row.person && row.person.toLowerCase().includes(value)) ||
        (row.description && row.description.toLowerCase().includes(value)) ||
        (row.scheduledTime && row.scheduledTime.toLowerCase().includes(value)) ||
        (row.actualTime && row.actualTime.toLowerCase().includes(value)) ||
        (row.scheduledPay && row.scheduledPay.toLowerCase().includes(value)) ||
        (row.actualPay && row.actualPay.toLowerCase().includes(value))
      );
    });
    setRows(filteredRows);
  };

  const handleChange = event => {
    setSelectedAction(event.target.value);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch('');
  };

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleSyncList = () => {
    setSelectedPerson(null);
    setSearched('');
    setTimePeriod({ id: 3, text: 'Current week' });
    setValues({
      people: '',
      location: '',
      position: '',
      department: '',
      subcontractor: '',
      from_date: moment(handleFilterCurrentWeekStartDate()).format('YYYY-MM-DD'),
      to_date: moment(handleFilterCurrentWeekEndDate()).format('YYYY-MM-DD'),
      newPage: 1,
    });
  };

  return (
    <div className="entities-reports-main-container bootstrap-scope">
      {loading && <Spinner />}
      <div className="entities-reports-filter-area ">
        <Grid container spacing={2} style={{ flexWrap: 'nowrap', width: '65%' }}>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <FormControl variant="outlined" size="small">
              <SearchBar
                value={searched}
                onChange={searchVal => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
                placeholder="Search Here..."
                style={{
                  outline: '1px solid #bbc4c2',
                  borderRadius: '4px',
                  height: '40px',
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                id="date"
                placeholder="From"
                inputVariant="outlined"
                className="entities_report_filter_fields datePicker"
                name="from_date"
                size="small"
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                  </>
                )}
                labelFunc={selectedDateFrom ? dateFTConverter : () => 'From'}
                value={selectedDateFrom ? moment(selectedDateFrom).format('YYYY-MM-DD') : null}
                onChange={handleDateFrom}
                InputLabelProps={{ shrink: true }}
                disabled={loading}
              />{' '}
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                id="date"
                size="small"
                inputVariant="outlined"
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                  </>
                )}
                labelFunc={selectedDateTo ? dateFTConverter : () => 'Till'}
                value={selectedDateTo ? moment(selectedDateTo).format('YYYY-MM-DD') : null}
                className="entities_report_filter_fields datePicker"
                name="to_date"
                placeholder="Till"
                onChange={handleDateTo}
                InputLabelProps={{ shrink: true }}
                disabled={loading}
              />{' '}
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <FormControl variant="outlined" fullWidth className="entities_report_filter_fields staffFilterTimeSheet">
              <Autocomplete
                options={
                  props.personsList &&
                  props.personsList.map(person => ({
                    id: person.id,
                    text: person.first_name + ' ' + person.last_name,
                    name: 'people',
                  }))
                }
                noOptionsText={'No Records'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.text || ''}
                onChange={handlePersonFilterValues}
                value={selectedPerson}
                disabled={loading}
                renderInput={params => <TextField {...params} variant="outlined" size="small" placeholder="Staff" />}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container className="entities_reports_refresh_and_download_div" style={{ width: '35%' }}>
          <IconButton
            size="small"
            className="report-download-button"
            id="entities_reports_btn"
            onClick={handleToggle}
            disabled={props.loading}
          >
            <GetAppIcon fontSize="large" />
            {open && (
              <div className="export-menu">
                <MenuItem>
                  <div style={{ display: 'block', color: 'black' }}>
                    <span style={{ textAlign: 'left' }} onClick={() => convertToPDF(true, jsonData, selectedDateFrom, selectedDateTo)}>
                      Export PDF
                    </span>
                    <br />
                    <br />
                    <span style={{ textAlign: 'left' }}>
                      <CSVLink className="csvLinks" {...csvReport}>
                        Export CSV
                      </CSVLink>
                    </span>
                    <br />
                    <br />
                    <span style={{ textAlign: 'left' }}>
                      <CSVLink className="csvLinks" {...csvReportDetailed}>
                        Export Detailed CSV
                      </CSVLink>
                    </span>
                  </div>
                </MenuItem>
              </div>
            )}
          </IconButton>

          <IconButton
            size="small"
            className="report-download-button"
            onClick={handleSyncList}
            id="entities_reports_btn"
            disabled={props.loading}
          >
            <AutorenewIcon fontSize="large" />
          </IconButton>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                options={
                  timePeriod &&
                  timePeriod.map(time => ({
                    id: time.value,
                    text: time.label,
                  }))
                }
                noOptionsText={'--Period--'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.text || ''}
                onChange={handleTimePeriodFilter}
                value={selectedTimePeriod}
                disabled={loading}
                fullWidth
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    className="personAutocompleteTextfield entities_report_filter_fields"
                    placeholder="Time period"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>
      <div>
        <Table
          columns={columns}
          data={Tabledata}
          timesheetSummaryList={timesheetSummaryList}
          currency={currency}
          renderRowSubComponent={renderRowSubComponent}
          loading={loading}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (setState: IRootState) => ({
  timesheetSummaryList: setState.timesheetSummary.entities,
  personsList: setState.person.filterPersons,
  locationsList: setState.serviceArea.entities,
  subcontractorsList: setState.subcontractor.entities,
  positionList: setState.designation.entities,
  loading: setState.timesheetSummary.loading,
  setting: setState.PersonalSettings.storedPersonalSettings,
  settings: setState.organizationSettings.storedOrganizationSettings,
});

const mapDispatchToProps = {
  getReport,
  getPersons,
  getLocations,
  getPositions,
  getPersonalSettings,
  getOrganizationSettings,
  getSubcontractors,
  resetLoc,
  resetPerson,
  resetSub,
  resetPosition,
  getPersonForFilters,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetSummary);
