/* eslint-disable complexity */

import { defaultValue, IMeasurements, IPerson, IPersonDocs } from 'app/shared/model/person.model';
import { IBannedEmployees } from 'app/shared/model/service-area.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';
import moment from 'moment';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, Storage } from 'react-jhipster';
import { ICountry } from 'app/shared/model/country.model';
import { toast, ToastContainer } from 'react-toastify';
import { store } from '../../index';
import { getPersonsList as getCalendarPersons } from '../../modules/calender/calendar.reducer';
import { IServiceArea } from 'app/shared/model/service-area.model';
import { getActivePersonsByServiceAreaId } from '../service-area/service-area.reducer';

export const ACTION_TYPES = {
  FETCH_PERSON_LIST: 'person/FETCH_PERSON_LIST',
  FETCH_PERSON: 'person/FETCH_PERSON',
  CREATE_PERSON: 'person/CREATE_PERSON',
  UPDATE_PERSON: 'person/UPDATE_PERSON',
  PARTIAL_UPDATE_PERSON: 'person/PARTIAL_UPDATE_PERSON',
  DELETE_PERSON: 'person/DELETE_PERSON',
  DELETE_STAFF: 'person/DELETE_STAFF',
  RESET: 'person/RESET',
  FETCH_ROLES_LIST: 'roles/GET_ROLES_LIST',
  UPLOAD_AVATAR_SETTINGS: 'settings/UPLOAD_AVATAR_SETTINGS',
  VERIFY_SIA_LICENCE_NO: 'person/VERIFY_SIA_LICENCE_NO',
  FETCH_BANNED_SERVICE_AREAS: 'person/FETCH_BANNED_SERVICE_AREAS',
  FILE_GET_SUCCESS: 'person/FILE_GET_SUCCESS',
  FILE_DEL_SUCCESS: 'person/FILE_DEL_SUCCESS',
  SET_PAGE_NUMBER: 'person/SET_PAGE_NUMBER',
  GET_ALL_COUNTRY: 'person/GET_ALL_COUNTRY',
  ADD_IMAGE: 'client/ADD_IMAGE',
  GET_PERSON_DOCUMENTS: 'person/GET_PERSON_DOCUMENTS',
  GET_PERSON_DOCUMENTS_DATA: 'person/GET_PERSON_DOCUMENTS_DATA',

  FETCH_DOCUMENT_TYPES: 'api/addPersonDocuments',
  DELETE_PERSON_DOCUMENT: 'person/DELETE_PERSON_DOCUMENT',
  SAVE_MEASUREMENTS: 'person/SAVE_MEASUREMENTS',
  SAVE_NOTIFICATION_SETTINGS: 'person/SAVE_NOTIFICATION_SETTINGS',
  ADD_SERVICE_AREA: 'serviceArea/ADD_SERVICE_AREA',
  FETCH_SERVICE_AREAS_BY_PERSON_ID: 'serviceArea/FETCH_SERVICE_AREAS_BY_PERSON_ID',
  FETCH_ACTIVE_SERVICE_AREAS_BY_PERSON_ID: 'serviceArea/FETCH_ACTIVE_SERVICE_AREAS_BY_PERSON_ID',
  FETCH_PAST_PERSON_LIST: 'person/FETCH_PAST_PERSON_LIST',
  FETCH_FILTER_PERSON_LIST: 'person/FETCH_FILTER_PERSON_LIST',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IPerson>,
  entity: defaultValue,
  roles: [],
  updating: false,
  updateSuccess: false,
  uploadedAvatar: '',
  siaLicenceInfo: { status: null, expiry: null },
  banned: [] as ReadonlyArray<IBannedEmployees>,
  AllCountryEntity: [] as Array<ICountry>,
  documentData: { ext: null, file: null },
  fileDelete: null,
  personDocuments: [] as Array<IPersonDocs>,
  pageNumber: 1,
  documentTypes: [],
  personServiceAreas: [] as ReadonlyArray<IServiceArea>,
  personActiveServiceAreas: [] as ReadonlyArray<IServiceArea>,
  past_entities: [] as ReadonlyArray<IPerson>,
  filterPersons: [] as ReadonlyArray<IPerson>,
};

export type PersonState = Readonly<typeof initialState>;

// Reducer

export default (state: PersonState = initialState, action): PersonState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PERSON_LIST):
    case REQUEST(ACTION_TYPES.FETCH_FILTER_PERSON_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PAST_PERSON_LIST):
    case REQUEST(ACTION_TYPES.FETCH_BANNED_SERVICE_AREAS):
    case REQUEST(ACTION_TYPES.FETCH_SERVICE_AREAS_BY_PERSON_ID):
    case REQUEST(ACTION_TYPES.FETCH_ACTIVE_SERVICE_AREAS_BY_PERSON_ID):
    case REQUEST(ACTION_TYPES.FETCH_PERSON):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PERSON):
    case REQUEST(ACTION_TYPES.UPDATE_PERSON):
    case REQUEST(ACTION_TYPES.DELETE_PERSON):
    case REQUEST(ACTION_TYPES.DELETE_STAFF):
    case REQUEST(ACTION_TYPES.FETCH_ROLES_LIST):
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_TYPES):
    case REQUEST(ACTION_TYPES.DELETE_PERSON_DOCUMENT):
    case REQUEST(ACTION_TYPES.PARTIAL_UPDATE_PERSON):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.GET_ALL_COUNTRY):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.ADD_IMAGE):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.SAVE_MEASUREMENTS):
    case REQUEST(ACTION_TYPES.SAVE_NOTIFICATION_SETTINGS):
    case FAILURE(ACTION_TYPES.FETCH_PERSON_LIST):
    case FAILURE(ACTION_TYPES.FETCH_FILTER_PERSON_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PAST_PERSON_LIST):
    case FAILURE(ACTION_TYPES.FETCH_BANNED_SERVICE_AREAS):
    case FAILURE(ACTION_TYPES.FETCH_PERSON):
    case FAILURE(ACTION_TYPES.CREATE_PERSON):
    case FAILURE(ACTION_TYPES.FETCH_SERVICE_AREAS_BY_PERSON_ID):
    case FAILURE(ACTION_TYPES.FETCH_ACTIVE_SERVICE_AREAS_BY_PERSON_ID):
    case FAILURE(ACTION_TYPES.UPDATE_PERSON):
    case FAILURE(ACTION_TYPES.PARTIAL_UPDATE_PERSON):
    case FAILURE(ACTION_TYPES.FETCH_ROLES_LIST):
    case FAILURE(ACTION_TYPES.FETCH_DOCUMENT_TYPES):
    case FAILURE(ACTION_TYPES.SAVE_MEASUREMENTS):
    case FAILURE(ACTION_TYPES.SAVE_NOTIFICATION_SETTINGS):
    case FAILURE(ACTION_TYPES.DELETE_PERSON):
    case FAILURE(ACTION_TYPES.DELETE_STAFF):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.GET_ALL_COUNTRY):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.DELETE_PERSON_DOCUMENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
      };
    case FAILURE(ACTION_TYPES.ADD_IMAGE):
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ROLES_LIST):
      return {
        ...state,
        loading: false,
        roles: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_TYPES):
      return {
        ...state,
        documentTypes: action.payload.data,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PERSON_LIST):
      return {
        ...state,
        loading: false,
        entities: [...state.entities, ...action.payload.data],
      };
    case SUCCESS(ACTION_TYPES.FETCH_FILTER_PERSON_LIST):
      return {
        ...state,
        loading: false,
        filterPersons: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PAST_PERSON_LIST):
      return {
        ...state,
        loading: false,
        past_entities: [...state.past_entities, ...action.payload.data],
      };
    case SUCCESS(ACTION_TYPES.FETCH_BANNED_SERVICE_AREAS):
      return {
        ...state,
        loading: false,
        banned: [...action.payload.data],
      };
    case SUCCESS(ACTION_TYPES.FETCH_PERSON):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SERVICE_AREAS_BY_PERSON_ID):
      return {
        ...state,
        loading: false,
        personServiceAreas: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ACTIVE_SERVICE_AREAS_BY_PERSON_ID):
      return {
        ...state,
        loading: false,
        personActiveServiceAreas: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_ALL_COUNTRY):
      return {
        ...state,
        updating: false,
        loading: false,
        AllCountryEntity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PERSON):
    case SUCCESS(ACTION_TYPES.UPDATE_PERSON):
    case SUCCESS(ACTION_TYPES.SAVE_MEASUREMENTS):
    case SUCCESS(ACTION_TYPES.SAVE_NOTIFICATION_SETTINGS):
    case SUCCESS(ACTION_TYPES.PARTIAL_UPDATE_PERSON):
      return {
        ...state,
        updating: false,
        loading: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PERSON):
      return {
        ...state,
        updating: false,
        loading: false,
        updateSuccess: true,
        entity: {},
      };
    case SUCCESS(ACTION_TYPES.DELETE_STAFF):
      return {
        ...state,
        updating: false,
        loading: false,
        updateSuccess: true,
        entity: {},
      };
    case SUCCESS(ACTION_TYPES.GET_PERSON_DOCUMENTS):
      return {
        ...state,
        updating: false,
        loading: false,
        updateSuccess: true,
        personDocuments: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_PERSON_DOCUMENTS_DATA):
      return {
        ...state,
        updating: false,
        loading: false,
        updateSuccess: true,
        documentData: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PERSON_DOCUMENT):
      return {
        ...state,
        updating: false,
        loading: false,
        updateSuccess: true,
      };

    case ACTION_TYPES.SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.VERIFY_SIA_LICENCE_NO:
      return {
        ...state,
        siaLicenceInfo: action.payload,
      };
    default:
      return state;
  }
};

const saveDocUrl = 'api/addPersonDocuments';
const apiUrl = 'api/person';
const apiImageUrl = 'api/personImage';
const apiRolesUrl = 'api/role';
const apiUrlAllCountry = 'api/getAllCountries';
const qualificationDocs = 'api/qualificationDocuments';
const getPersonByUid = 'api/getPersonByUId';

export const getEntities = (pageNo, searchParams) => ({
  type: ACTION_TYPES.FETCH_PERSON_LIST,
  payload: axios.get<IPerson>(`${apiUrl}?cacheBuster=${new Date().getTime()}&page=${pageNo}&q=${searchParams}`),
});

export const getRoles = () => ({
  type: ACTION_TYPES.FETCH_ROLES_LIST,
  payload: axios.get(`${apiRolesUrl}?cacheBuster=${new Date().getTime()}`),
});

export const getEntity: ICrudGetAction<IPerson> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PERSON,
    payload: axios.get<IPerson>(requestUrl),
  };
};

export const getAllCountry: ICrudGetAllAction<ICountry> = () => ({
  type: ACTION_TYPES.GET_ALL_COUNTRY,
  payload: axios.get<ICountry>(`${apiUrlAllCountry}`),
});

export const createEntity = (entity, files, docs) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_PERSON,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  if (files && !!files.entries().next().value === true) {
    dispatch(img(files, result));
  }
  if (docs && !!docs.entries().next().value === true) {
    dispatch(saveQualificationDocuments(result.value.data.id, docs));
  }
  toast.success('Person Created Successfully');
  if (result?.value?.status === 201) {
    dispatch(getCalendarPersons({ from_date: '', to_date: '', current_day: moment().format('YYYY-MM-DD') }));
  }
  return result;
};

export const savePersonDocuments = (id, files) => dispatch => {
  axios
    .post(saveDocUrl, files, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(res => {
      toast.success('Person Document submitted');
      dispatch(getPersonDocuments(id));
    })
    .catch(err => {
      toast.error('Person Document Submission Failed');
    });
};

export const saveQualificationDocuments = (id, files) => dispatch => {
  files.append('person_id', id);
  axios
    .post(qualificationDocs, files, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(res => {})
    .catch(err => {
      toast.error('Person Qualification Document Submission Failed');
    });
};

export const updateEntity = (entity, files, docs) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PERSON,
    payload: axios.put(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  if (!!files.entries().next().value === true) {
    dispatch(img(files, result));
  }
  if (!!docs.entries().next().value === true) {
    dispatch(saveQualificationDocuments(result.value.data.id, docs));
  }
  toast.success('Person Edited Successfully');
  return result;
};
const apiUrlDocTypes = 'api/addPersonDocuments';
export const getDocTypes = () => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_TYPES,
  payload: axios.get(`${apiUrlDocTypes}`),
});

const apiUrlDocs = 'api/getPersonDocuments';
export const getPersonDocuments = id => ({
  type: ACTION_TYPES.GET_PERSON_DOCUMENTS,
  payload: axios.get<IPersonDocs>(`${apiUrlDocs}/${id}`),
});

const apiUrlDeleteDocs = 'api/deletePersonDocument';
export const deletePersonDocument = (id, subcontractor_id) => dispatch => {
  const result = dispatch({
    type: ACTION_TYPES.DELETE_PERSON_DOCUMENT,
    payload: axios.delete(`${apiUrlDeleteDocs}/${id}`, cleanEntity(subcontractor_id)),
  });
  dispatch(getPersonDocuments(subcontractor_id));
  return result;
};

export const partialUpdate: ICrudPutAction<IPerson> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.PARTIAL_UPDATE_PERSON,
    payload: axios.patch(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IPerson> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PERSON,
    payload: axios.delete(requestUrl),
  });
  dispatch(reset());
  dispatch(setPageNumber({ pageNumber: 1 }));
  toast.success('Person Deleted Successfully');
  return result;
};

const tem = 'api/deleteActivePersonForServiceAreas';

export const deleteStaffEntity = (serviceAreaId, id) => async dispatch => {
  const requestUrl = `${tem}/${serviceAreaId}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_STAFF,
    payload: axios.delete(requestUrl),
  });
  dispatch(getActiveServiceAreasByPersonId(id));
  dispatch(getActivePersonsByServiceAreaId(serviceAreaId));
  return result;
};

export const img = (files, res) => async dispatch => {
  files.append('id', res.value.data.id);
  const result = await dispatch({
    type: ACTION_TYPES.ADD_IMAGE,
    payload: axios.post(apiImageUrl, files, { headers: { 'Content-Type': 'multipart/form-data' } }),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const verifyEmail = async emailAddress => {
  const response = await axios.post('api/emailValidation', { email: emailAddress }).catch(err => {
    return err;
  });
  return response;
};

export const verifySiaLicenceNumber = async siaLicenceNumber => {
  const response = await axios.post('api/siaLicenceChecker', { LicenceNo: siaLicenceNumber }).catch(err => {
    return err;
  });
  return response;
};

export const getBannedServiceAreas: ICrudPutAction<IBannedEmployees> = object => {
  const requestUrl = `api/banned-serviceAreas/person/${object.id}`;
  return {
    type: ACTION_TYPES.FETCH_BANNED_SERVICE_AREAS,
    payload: axios.get<IBannedEmployees>(requestUrl),
  };
};

export const setPageNumber: ICrudPutAction<{ pageNumber: number }> = pageNo => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.SET_PAGE_NUMBER,
    payload: pageNo,
  });
  dispatch(getEntities(pageNo['pageNumber'], ''));
  return result;
};

export const saveMeasurements = (values, id) => dispatch => {
  const result = dispatch({
    type: ACTION_TYPES.SAVE_MEASUREMENTS,
    payload: axios.put(`api/personMeasurements/${id}`, values),
  });
  toast.success('Measurements Saved Successfully');
  return result;
};

export const saveNotificationSettings = (values, id) => dispatch => {
  const result = dispatch({
    type: ACTION_TYPES.SAVE_NOTIFICATION_SETTINGS,
    payload: axios.put(`api/personNotificationSettings/${id}`, values),
  });
  toast.success('Settings Saved Successfully');
  return result;
};

const apiUrlDoc = 'api/getPersonDocumentsById';
export const getDocumentById = id => ({
  type: ACTION_TYPES.GET_PERSON_DOCUMENTS_DATA,
  payload: axios.get<IPersonDocs>(`${apiUrlDoc}/${id}`),
});

export const getServiceAreasByPersonId = id => ({
  type: ACTION_TYPES.FETCH_SERVICE_AREAS_BY_PERSON_ID,
  payload: axios.get<IServiceArea>(`api/getServiceAreasforPerson/${id}`),
});

export const getActiveServiceAreasByPersonId = id => ({
  type: ACTION_TYPES.FETCH_ACTIVE_SERVICE_AREAS_BY_PERSON_ID,
  payload: axios.get<IServiceArea>(`api/getActiveServiceAreasforPerson/${id}`),
});

export const addNewServiceArea = data => dispatch => {
  axios
    .post(`api/addNewServiceArea`, cleanEntity(data))
    .then(res => {
      dispatch({
        type: ACTION_TYPES.ADD_SERVICE_AREA,
        payload: res,
      });
      dispatch(getActiveServiceAreasByPersonId(data?.person.id));
    })
    .catch(err => {
      return err.errorMessage;
    });
};

const apiPastEmployee = 'api/getPastEmployees';
export const getPastEmployee = (pageNo, searchParams) => ({
  type: ACTION_TYPES.FETCH_PAST_PERSON_LIST,
  payload: axios.get<IPerson>(`${apiPastEmployee}?cacheBuster=${new Date().getTime()}&page=${pageNo}&q=${searchParams}`),
});

export const setPastPageNumber: ICrudPutAction<{ pageNumber: number }> = pageNo => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.SET_PAGE_NUMBER,
    payload: pageNo,
  });
  dispatch(getPastEmployee(pageNo['pageNumber'], ''));
  return result;
};

const apiPersonFilter = 'api/getPersonForFilters';
export const getPersonForFilters = () => ({
  type: ACTION_TYPES.FETCH_FILTER_PERSON_LIST,
  payload: axios.get<IPerson>(`${apiPersonFilter}`),
});

export const getPersonByUId = id => ({
  type: ACTION_TYPES.FETCH_PERSON,
  payload: axios.get<IPerson>(`${getPersonByUid}/${id}`),
});
